// polyfills imported here will be bundled up in main.js and therefore be served to clients on initial load
// we currently polyfill only older browsers not supporting es6 modules using a <script nomodule> tag in _document.tsx.
// for more information, see README.md

// if we later decide to add polyfills for newer browsers as well, add them like this:
// import assign from 'core-js/library/fn/object/assign'
// Object.assign = assign

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).Promise && !(window as any).Promise.finally) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).Promise.finally = require('core-js/features/promise/finally')
}

if (!String.prototype.matchAll) {
    String.prototype.matchAll = require('core-js/features/string/match-all')
}

/* eslint-disable-next-line import/no-unassigned-import */
require('intersection-observer')

import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
smoothscroll.polyfill()

if (!('globalThis' in window)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).globalThis = window
}

// next.js suggests to export undefined https://github.com/zeit/next.js/issues/7959 but this causes a babel warning
// so we now export null and it seems to work
/* eslint-disable-next-line import/no-default-export */
export default null
